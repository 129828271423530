import React, { Component } from 'react';
import { Button, MenuButton, Menu, MenuItem, Box, Link, Flex } from "monday-ui-react-core";
import { EditableHeading, IconButton } from "monday-ui-react-core";
import { Add, Chart, LongText, Table, TextMedium, Delete } from "monday-ui-react-core/icons";
import mondaySdk from "monday-sdk-js";
import axios from 'axios';
import DOMPurify from 'dompurify';
import { TableDefinitionEdit } from './TableDefinitionEdit';
import { ChartDefinitionEdit } from './ChartDefinitionEdit';
import { TemplateElementTextEdit } from './TemplateElementTextEdit';
import { downloadExistingTemplate } from './fileDownloader';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class TemplateBuilder extends Component {
    static displayName = TemplateBuilder.name;

    constructor(props) {
        super(props);

        let firstHeader = {
            id: this.generateUniqueID(),
            elementType: 'Header',
            content: 'Report name',
        };

        this.state = {
            loading: true,  
            isSaving: false,            
            elements: [firstHeader],
            createNewTableDefinition: false,
            createNewChartDefinition: false,
            editText: false,
            selectedElement: null,
            finetuneTemplate: false,
        };
        this.mondayContext = window.mondayContext;
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleAddText = this.handleAddText.bind(this);
        this.handleAddChart = this.handleAddChart.bind(this);
        this.handleAddHeader = this.handleAddHeader.bind(this);
        this.handleAddTable = this.handleAddTable.bind(this);
        this.handleTableEditClose = this.handleTableEditClose.bind(this);
        this.handleChartEditClose = this.handleChartEditClose.bind(this);
        this.handleHeaderContentChange = this.handleHeaderContentChange.bind(this);
        this.handleElementTextEditClose = this.handleElementTextEditClose.bind(this);
        this.handleFinetuneTemplateCheckboxChange = this.handleFinetuneTemplateCheckboxChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    generateUniqueID() {
        return 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
    }

    handleAddTable() {
        this.setState({ createNewTableDefinition: true });
    }

    handleTableEditClose(table) {
        //console.log(table);
        if (table) {
            var newElement = {
                id: this.generateUniqueID(),
                elementType: 'Table',
                tableDefinitionId: table.id,
                name: table.name,
            }
            this.setState({ elements: [...this.state.elements, newElement] });
        }
            
        this.setState({ createNewTableDefinition: false });        
    }

    handleAddText() {
    //    var newElement = {
    //        id: this.generateUniqueID(),
    //        elementType: 'Text',
    //        //name: 'New text',
    //        content: ''
    //    }
        //    this.setState({ elements: [...this.state.elements, newElement], selectedElement: newElement, editText: true });
        this.setState({ editText: true, selectedElement: null });
    }

    handleElementTextEditClose(newText) {
        //console.log(newText);
        //if (newText) {
        //    const newElements = this.state.elements.map(element => {
        //        if (element.id === this.state.selectedElement.id) {
        //            return { ...element, content: DOMPurify.sanitize(newText) };
        //        }
        //        return element;
        //    });
        //    this.setState({ elements: newElements });
        //}

        if (newText) {
            if (this.state.selectedElement) {
                const newElements = this.state.elements.map(element => {
                    if (element.id === this.state.selectedElement.id) {
                        return { ...element, content: DOMPurify.sanitize(newText) };
                    }
                    return element;
                });
                this.setState({ elements: newElements });
            }
            else {
                var newElement = {
                    id: this.generateUniqueID(),
                    elementType: 'Text',
                    content: DOMPurify.sanitize(newText)
                }
                this.setState({ elements: [...this.state.elements, newElement] });
            }
        }

        this.setState({ editText: false, selectedElement: null });        
    }

    handleAddChart() {
        this.setState({ createNewChartDefinition: true });
    }

    handleChartEditClose(chartDefinition) {
        if (chartDefinition) {
            var newElement = {
                id: this.generateUniqueID(),
                elementType: 'Chart',
                chartDefinitionId: chartDefinition.id,
                name: chartDefinition.name,
            }
            this.setState({ elements: [...this.state.elements, newElement] });
        }
        this.setState({ createNewChartDefinition: false });
    }

    handleAddHeader() {
        var newElement = {
            id: this.generateUniqueID(),
            elementType: 'Header',
            content: 'New header',
        }
        this.setState({ elements: [...this.state.elements, newElement] });
    }
    
    handleCancelClick() {
        this.props.onClose();
    }

    async handleClickSave() {
        this.setState({ isSaving: true });
        const data = this.getDataForPOST();
        try {
            const response = await axios.post('templatedefinition', data);
            //console.log(response.data);
            if (response.data.result) {
                if (this.state.finetuneTemplate) {
                    response.data.template.finetuneTemplate = true;
                    await downloadExistingTemplate(response.data.template, window.mondaySessionToken);                    
                }
                this.setState({ isSaving: false });
                this.props.onClose(response.data.template, response.data.templateDefinition);
            } else {
                var errorMessage = 'Error saving template definition: ' + response.data.error;
                console.log(errorMessage);
                alert(errorMessage);
                this.setState({ isSaving: false });
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error);
            this.setState({ isSaving: false });
        }
    }

    getDataForPOST() {
        const data = {            
            accountId: this.mondayContext.account?.id,
            boardId: this.mondayContext.boardId,
            workspaceId: this.mondayContext.workspaceId,
            userCountryCode: this.mondayContext.user.countryCode,
            userLanguage: this.mondayContext.user.currentLanguage,
            sessionToken: window.mondaySessionToken,
            dataSource: DOMPurify.sanitize(this.props.dataSource),
            elements: this.state.elements,  // TODO: DOMPurify
        };
        return data;
    }

    handleFinetuneTemplateCheckboxChange() {
        this.setState({ finetuneTemplate: !this.state.finetuneTemplate });
    }

    cannotSave() {
        return false;
    }

    handleHeaderContentChange = (id, newContent) => {
        const newElements = this.state.elements.map(element => {
            if (element.id === id) {
                return { ...element, content: newContent };
            }
            return element;
        });

        this.setState({ elements: newElements });
    };

    deleteElement = (e, id) => {
        e.stopPropagation();
        this.setState(prevState => ({
            elements: prevState.elements.filter(element => element.id !== id)
        }));
    };

    handleElementClick = (e, id) => {
        e.stopPropagation();
        const selectedElement = this.state.elements.find(element => element.id === id);
        if (!selectedElement)
            return;
        if (selectedElement.elementType === 'Text')
            this.setState({ selectedElement: selectedElement, editText: true });
    }

    renderElements() {
        const { elements } = this.state;

        return (
            <div>
                {elements.map((element) => {
                    const { id, elementType, content, name } = element;
                    const boxClassName = `app-spirit-template-builder-element app-spirit-template-builder-element-${elementType}`;
                    let border = (elementType === 'Chart' || elementType === 'Table') ? Box.borders.DEFAULT : null;

                    return (
                        <Box
                            key={id}
                            border={border}
                            className={boxClassName}                            
                        >
                            {this.renderElementContent(elementType, content, name, id)}
                            <IconButton                                
                                size={IconButton.sizes.SMALL}
                                icon={Delete}
                                onClick={(e) => this.deleteElement(e, id)}
                                className="app-spirit-template-builder-delete-button"
                            />
                        </Box>
                    );
                })}
            </div>
        );
    }

    renderElementContent(elementType, content, name, id) {
        switch (elementType) {
            case 'Header':
                return (
                    <EditableHeading
                        value={content}
                        onChange={(text) => this.handleHeaderContentChange(id, text)}
                    />
                );
            case 'Text':
                const sanitizedContent = DOMPurify.sanitize(content);
                return <div onClick={(e) => this.handleElementClick(e, id)} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
            case 'Chart':
                return <p>Chart {name}</p>;
            case 'Table':
                return <p>Table {name}</p>;
            default:
                return null;
        }
    }

    render() {
        if (this.state.loading)  // TODO: Loading animation
            return (<p><em>Loading...</em></p>);

        if (this.state.createNewTableDefinition) {
            return (
                <TableDefinitionEdit
                    columns={this.props.columns}
                    subItemsColumns={this.props.subItemsColumns}
                    dataSource={this.props.dataSource}
                    onClose={this.handleTableEditClose}></TableDefinitionEdit>
            );
        }

        if (this.state.createNewChartDefinition) {
            return (
                <ChartDefinitionEdit onClose={this.handleChartEditClose}></ChartDefinitionEdit>
            );
        }

        if (this.state.editText) {
            return (
                <TemplateElementTextEdit
                    content={this.state.selectedElement?.content}
                    columns={this.props.columns}
                    subItemsColumns={this.props.subItemsColumns}
                    dataSource={this.props.dataSource}
                    isReportGenerator={this.props.isReportGenerator}
                    onClose={this.handleElementTextEditClose}></TemplateElementTextEdit>
            );
        }

        let elements = this.renderElements();

        return (
            <Flex align={Flex.align.START} gap={32}>
                <div>
                    <p>Please build your template:</p>
                    <Box className="app-spirit-template-builder-box"
                        border={Box.borders.DEFAULT}
                        backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR}
                        rounded={Box.roundeds.MEDIUM}
                        shadow={Box.shadows.MEDIUM}>
                        {elements}
                        <br />
                        <div>
                            <MenuButton
                                text="Add new element"
                                className="app-spirit-template-builder-add-button"
                                component={Add}
                                componentPosition={MenuButton.componentPositions.END}
                                onMenuHide={function noRefCheck() { }}
                                onMenuShow={function noRefCheck() { }}>
                                <Menu
                                    id="menu"
                                    size="medium">
                                    <MenuItem
                                        icon={LongText}
                                        onClick={this.handleAddText}
                                        title="Text"
                                    />
                                    <MenuItem
                                        icon={Table}
                                        onClick={this.handleAddTable}
                                        title="Table"
                                    />
                                    <MenuItem
                                        icon={TextMedium}
                                        onClick={this.handleAddHeader}
                                        title="Header"
                                    />
                                    {this.props.dataSource === "Board" && <MenuItem
                                        icon={Chart}
                                        onClick={this.handleAddChart}
                                        title="Chart"
                                    />}
                                </Menu>
                            </MenuButton>
                        </div>
                    </Box>
                    <div>
                        {/*<p></p>*/}
                        {/*<Checkbox onChange={this.handleFinetuneTemplateCheckboxChange} checked={this.state.finetuneTemplate}*/}
                        {/*    label="Finetune template in Microsoft Word"*/}
                        {/*/>*/}
                        <p></p>
                        <Button loading={this.state.isSaving} disabled={this.cannotSave()} onClick={this.handleClickSave}>
                            Next
                        </Button>
                        <Button kind={Button.kinds.SECONDARY} className="app-spirit-cancelbutton" onClick={this.handleCancelClick}>
                            Cancel
                        </Button>
                    </div>
                </div>
                <div>
                    <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                        <p className="app-spirit-explanation-text-top">How does the Report Builder work?</p>
                        <p>Put together your report using various elements:</p>
                        <ol>
                            <li>Add new elements to your report by clicking "Add new element".</li>
                            <li>Select the elements you want to add: Texts, <a href="https://www.docexport.com/help/template/tables/" target="_blank" rel="noreferrer">Tables</a>,&nbsp;
                                {this.props.dataSource === "Board" && (<><a href="https://www.docexport.com/help/template/charts/" target="_blank" rel="noreferrer">Charts</a>, </>)}
                                Headers</li>
                            <li>Report Generator then creates a Word template with all the elements you chose. You can download the document as a Word file to make further adjustments, such as changing colors or fonts, by clicking "Download template" after the initial report generation.</li>
                        </ol>
                        {/*<p className="app-spirit-explanation-text-bottom"><Link href="https://www.docexport.com/help/template/" text="Help Center" /></p>*/}
                    </Box>
                </div>
            </Flex>
        );
    }
}