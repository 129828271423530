import React, { Component, createRef } from 'react';
import { Button, Flex } from "monday-ui-react-core";
import JoditEditor from 'jodit-react';
import { PlaceholderListMonday } from './PlaceholderListMonday';

export class TemplateElementTextEdit extends Component {
    static displayName = TemplateElementTextEdit.name;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            id: '',
            content: this.props.content ?? '',
        };

        this.editorConfig = {
            readonly: false, // all options from https://xdsoft.net/jodit/docs/,
            height: 400,            
            buttons: ['paragraph', 'bold', 'underline', 'italic', '|', 'ul', 'ol', '|', 'image', 'video', 'table', 'link', '|', 'align', 'undo', 'redo'],             
            addNewLine: false
        };

        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.editorRef = createRef();
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleEditorContentChange = this.handleEditorContentChange.bind(this);
        this.handlePlaceholderClick = this.handlePlaceholderClick.bind(this);        
    }

    componentDidMount() {
    }

    handleCancelClick() {
        this.props.onClose();
    }

    async handleClickSave() {
        this.props.onClose(this.state.content);
    }

    handleEditorContentChange(content) {
        this.setState({ content: content });
    }

    handlePlaceholderClick(placeholder) {
        if (this.editorRef.current && this.editorRef.current.component) {
            this.editorRef.current.component.s.insertHTML(placeholder);
        } else {
            console.error('Editor reference is not available');
        }
    }

    render() {
        return (
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-template-element-text-editor">
                    <JoditEditor
                        ref={this.editorRef}
                        value={this.state.content}
                        config={this.editorConfig}
                        onBlur={this.handleEditorContentChange}
                    />
                    <p></p>
                    <Button onClick={this.handleClickSave}>Save</Button>
                    <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={this.handleCancelClick}>Cancel</Button>
                </div>
                <div className="app-spirit-placeholder-list">
                    <PlaceholderListMonday
                        columns={this.props.columns}
                        subItemsColumns={this.props.subItemsColumns}
                        dataSource={this.props.dataSource}
                        isReportGenerator={this.props.isReportGenerator}
                        onPlaceholderClick={this.handlePlaceholderClick} />
                </div>
            </Flex>
        );
    }
}